import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useAppSelector } from "../../app/store/configureStore";
import React from "react";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { useGetCreditSummary } from "../../app/customHooks/useGetCreditSummary";
import { SummaryDto } from "../../app/models/pcs/summaryDto";
import { GetColorCode } from "../../app/utils/util";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    alignCenter: {
        textAlign: 'center'
    },
    gridItem: {
        textAlign: 'center',
        paddingTop: '16px'
    }
}));

export default function SummaryForm() {
    const queryParams = new URLSearchParams(window.location.search);
    const personId = queryParams.get("personId");

    const { summaryData, getCreditSummaryStatus } = useAppSelector(state => state.pcs);
    const classes = useStyles();

    useGetCreditSummary(personId);

    if (getCreditSummaryStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (<>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>6 Year Summary</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, padding: 2 }} component={Paper} className={classes.root}>

            <Grid container>
                <Grid item xs={1}><b>PCS year</b></Grid>
                <Grid item xs={2} className={classes.alignCenter}><b>Engaged in practice of medicine</b></Grid>
                <Grid item xs={2} className={classes.alignCenter}><b>Exceptional Circumnstances</b></Grid>
                <Grid item xs={1} className={classes.alignCenter}><b>Accredited CE Activity</b></Grid>
                <Grid item xs={1} className={classes.alignCenter}><b>PDP</b></Grid>
                <Grid item xs={1} className={classes.alignCenter}><b>Practice review</b></Grid>
                <Grid item xs={1} className={classes.alignCenter}><b>Workbased learning</b></Grid>
                <Grid item xs={1} className={classes.alignCenter}><b>On target</b></Grid>
                <Grid item xs={2} className={classes.alignCenter}><b>Verification status</b></Grid>

                {summaryData?.map((item: SummaryDto[], index: number) => (
                    <React.Fragment key={index}>

                        <Grid item xs={1}>
                            <Grid className={classes.gridItem} sx={{ color: 'primary.main' }}>{item[0].text}</Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <Grid className={classes.gridItem}>{item[1].text}</Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <Grid className={classes.gridItem}>{item[2].text.toLowerCase() === "true" ? "Yes" : "No"}</Grid>
                        </Grid>

                        <Grid item xs={1} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetColorCode(item[3].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}>{item[3].text}</Grid>
                        </Grid>

                        <Grid item xs={1} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetColorCode(item[4].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}>{item[4].text}</Grid>
                        </Grid>

                        <Grid item xs={1} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetColorCode(item[5].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}>{item[5].text}</Grid>
                        </Grid>

                        <Grid item xs={1} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetColorCode(item[6].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}>{item[6].text}</Grid>
                        </Grid>

                        <Grid item xs={1} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetColorCode(item[7].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}>{item[7].text}</Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <Grid className={classes.gridItem}>{item[8].text}</Grid>
                        </Grid>

                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    </>)
}