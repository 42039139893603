import { DropdownItem } from "../models/common/dropdownItem";

export const formatBytes = (bytes: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  let i = 0;

  for (i; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }

  return `${bytes.toFixed(2)} ${units[i]}`;
}

export const formatDateWithTime = (date: Date) => {

  date = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));

  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear(),
    hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours(),
    minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes(),
    second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  let formatedDate = [day, month, year].join('/');

  let formatTime = [hour, minute, second].join(':');

  return [formatedDate, formatTime].join(' ');
}

export const isProductionMode = (): boolean => {
  if (process.env.REACT_APP_ENV === 'PRODUCTION') {
    return true;
  }
  return false;
}

export function yesNoDropDownValues() {
  var yesNoObjects: DropdownItem[] = [];
  yesNoObjects.push({ id: "Yes", value: "Yes" });
  yesNoObjects.push({ id: "No", value: "No" });
  return yesNoObjects;
}

export function GetColorCode(colorName: string) {
  switch (colorName.toUpperCase()) {
    case "RED":
      return 'primary.main';
    case "AMBER":
      return "#e46c0a";
    case "GREEN":
      return "#00af66";
    case "WHITE":
      return "#FFFFFF";
    default:
      return "#FFFFFF";
  }
}