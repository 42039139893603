import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { SummaryDto } from "../models/pcs/summaryDto";
import { PCSSummaryDto } from "../models/pcs/pcsSummaryDto";

interface PcsState {
    summaryData: SummaryDto[][] | null;
    pcsSummaryDto: PCSSummaryDto | null;
    getCreditSummaryStatus: ApiRequestStatus;
}

const initialState: PcsState = {
    summaryData: null,
    pcsSummaryDto: null,
    getCreditSummaryStatus: ApiRequestStatus.Idle
}

export const getCreditSummaryAsync = createAsyncThunk<PCSSummaryDto, string>(
    'pcsSlice/getCreditSummaryAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.pcs.getCreditSummaryAsync(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const pcsSlice = createSlice({
    name: 'pcsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getCreditSummaryAsync.pending, (state) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCreditSummaryAsync.fulfilled, (state, action) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Fulfilled;
            state.summaryData = action.payload.summaryData;
            state.pcsSummaryDto = action.payload;
        });
        builder.addCase(getCreditSummaryAsync.rejected, (state) => {
            state.getCreditSummaryStatus = ApiRequestStatus.Rejected;
        });
    })
})
